<template>
  <RealisasiVolumePasokanForm mode="Tambah" module="Realisasi Volume Pasokan"> </RealisasiVolumePasokanForm>
</template>

<script>
import RealisasiVolumePasokanForm from './form';

const RealisasiVolumePasokanAdd = {
  name: 'RealisasiVolumePasokanAdd',
  components: { RealisasiVolumePasokanForm },
};

export default RealisasiVolumePasokanAdd;
</script>
